import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ProfileService } from '../services/profile.service';
import { map, retry, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { lastValueFrom, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RegistrationCompletedGuard implements CanActivate {
    constructor(private profileService: ProfileService, private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.isLoginOngoing().pipe(
            switchMap(() => {
                if (this.authService.isLoggedIn()) {
                    return this.profileService.isInitialRegistrationDone().pipe(
                        map(done => {
                            console.log(done);
                            if (done) {
                                return true;
                            } else {
                                this.router.navigate(['registration']);
                                return false;
                            }
                        })
                    );
                } else {
                    this.router.navigate(['login', {redirect: state.url}]);
                    return of(false);
                }
            })
        );
    }
}
