import { Component, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { SplashScreen } from '@capacitor/splash-screen';
import { NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { resolve } from 'dns';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  private languages = ['de', 'en'];
  private authEventSub;


  constructor(
    private translate: TranslateService,
    private platform: Platform,
    private authService: AuthService,
    private router: Router,
    private ngZone: NgZone,
    private navController: NavController) {
    this.initializeApp();
  }

  ngOnDestroy() {
    this.authEventSub.unsubscribe();
  }

  async initializeApp() {
    await this.authService.refreshToken();
    this.setupDeepLinkHandling();

    this.platform.ready().then(async () => {
      this.setupAppLanguageHandling();

      SplashScreen.hide();
    });
  }

  setupDeepLinkHandling() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.ngZone.run(() => {
        this.authService.refreshToken().then(() => {
          const domain = 'bbc-nord.at';
          const iosUrlScheme = 'bbcnord://';

          let pathArray = [];
          if (event.url.includes(iosUrlScheme)) {
            pathArray = event.url.split('bbcnord://');
          } else if(event.url.includes(domain)) {
            pathArray = event.url.split(domain);
          }

          console.log(event.url);
          console.log(pathArray);
          const appPath = pathArray.pop();
          if (appPath) {
            console.log(appPath);
            this.router.navigateByUrl(appPath, {replaceUrl: true}).then(() => Browser.close());
          }
        });
      });
    });
  }

  setupAppLanguageHandling() {
    this.translate.setDefaultLang('en');
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      this.translate.use(storedLanguage);
    } else {
      const languageCode = navigator.language.slice(0, 2);
      if (this.languages.includes(languageCode)) {
        this.translate.use(languageCode);
      } else {
        this.translate.use(this.translate.getDefaultLang());
      }
    }
  }
}
