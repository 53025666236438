import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GameWithTicket} from '../models/game.model';
import {environment} from 'src/environments/environment';
import { closestIndexTo, compareAsc, isAfter, isBefore } from 'date-fns';
import { CurrentSeason } from '../models/season.model';
import { ModalController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class GameService {

    constructor(private http: HttpClient, public modalController: ModalController) {
    }

    getGameWithTicketsByGameId(id: number): Observable<GameWithTicket> {
        return this.http.get<GameWithTicket>(environment.restUrl + 'games/' + id);
    }

    getGameWithTicketsByEventId(id: string): Observable<GameWithTicket> {
        return this.http.get<GameWithTicket>(environment.restUrl + 'games/event/' + id);
    }

    getGamesWithTickets(): Observable<GameWithTicket[]> {
      return this.http.get<GameWithTicket[]>(environment.restUrl + 'games');
    }

    getGamesAroundToday(games: GameWithTicket[]): GameWithTicket[] {
      const sortedGames = games.sort(this.sortTwoGamesAsc);
      const sortedGameDates: Date[] = games.map(value => new Date(value.gameInfo.date));

      const now = new Date();
      const indexOfNearest = closestIndexTo(now, sortedGameDates);

      let other;
      if (isAfter(new Date(sortedGames[indexOfNearest].gameInfo.date), now)) {
        other = (indexOfNearest >= 1) ? sortedGames[indexOfNearest-1] : null;
        return [other, sortedGames[indexOfNearest]];
      } else if (isBefore(new Date(sortedGames[indexOfNearest].gameInfo.date), now)) {
        other = (indexOfNearest <= sortedGames.length-2) ? sortedGames[indexOfNearest+1] : null;
        return [sortedGames[indexOfNearest], other];
      }
    }

    filterGamesInPast(games: GameWithTicket[]) {
        const sortedGames = games.sort(this.sortTwoGamesAsc);
        const now = new Date();

        return sortedGames.filter(val => isBefore(new Date(val.gameInfo.date), now));
    }

    filterGamesInFuture(games: GameWithTicket[]) {
        const sortedGames = games.sort(this.sortTwoGamesAsc);
        const now = new Date();

        return sortedGames.filter(val => isAfter(new Date(val.gameInfo.date), now));
    }

    getNextHomeGame(games: GameWithTicket[]): GameWithTicket {
        const sortedGames = games.sort(this.sortTwoGamesAsc);
        const now = new Date();

        return sortedGames.filter(val => isAfter(new Date(val.gameInfo.date), now) && this.isHome(val))[0];
    }

    private sortTwoGamesAsc(a: GameWithTicket, b: GameWithTicket) {
        return compareAsc(new Date(a.gameInfo.date), new Date(b.gameInfo.date));
      }

    private isHome(game: GameWithTicket): boolean {
        return game.gameInfo.teamName1 === 'COLDAMARIS BBC Nord Dragonz';
    }
}
