import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Capacitor } from '@capacitor/core';

import localeDeAt from '@angular/common/locales/de';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthExpiredInterceptor } from './interceptors/auth-expired.interceptor';
import { PipeModule } from './pipes/pipe.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';
import { SharedModule } from './components/shared.module';

registerLocaleData(localeDeAt);
export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

const initializeKeycloak =
  (keycloak: KeycloakService, authService: AuthService) => () =>
    keycloak.init({
      config: {
        url: environment.authConfig.serverUrl,
        realm: environment.authConfig.realm,
        clientId: environment.authConfig.clientId,
      },
      initOptions: {
        onLoad: Capacitor.isNativePlatform() ? null : 'check-sso',
        silentCheckSsoRedirectUri: !Capacitor.isNativePlatform() ? (window.location.origin + '/assets/silent-check-sso.html') : null,
        enableLogging: true,
        adapter: Capacitor.isNativePlatform() ? 'capacitor-native' : 'default',
        redirectUri: environment.authConfig.baseUri + environment.authConfig.redirectPath,
        flow: 'hybrid',
      },
      shouldAddToken: (request) => {
        const { method, url } = request;

        const isGetRequest = 'GET' === method.toUpperCase();
        const acceptablePaths = ['/assets', 'api.rss2json.com'];
        const isAcceptablePathMatch = acceptablePaths.some((path) => url.includes(path));

        return !(isGetRequest && isAcceptablePathMatch);
      }
    });

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    PipeModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    KeycloakAngularModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      }})
    ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide: LOCALE_ID, useValue: 'de-AT'},
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      deps: [KeycloakService, AuthService],
      multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthExpiredInterceptor,
        multi: true,
    },],
  bootstrap: [AppComponent],
})
export class AppModule {}
