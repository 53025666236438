import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'inputError'
})
export class InputErrorPipe implements PipeTransform {

    constructor(private translateService: TranslateService) {
    }

    transform(type: any, ...args: any[]): string {
        if (type !== null) {
            if (type.required === true) {
                return this.handleRequired();
            }
            if ('minlength' in type) {
                return this.handleMinLength(type);
            }
            if ('maxlength' in type) {
                return this.handleMaxLength(type);
            }
            if ('pattern' in type) {
                return this.handlePattern(type);
            }
        }
        return '';
    }

    private handleRequired() {
        return this.translateService.instant('VALIDATION_ERROR.REQUIRED');
    }

    private handleMinLength(type: any) {
        return this.translateService.instant('VALIDATION_ERROR.MIN_LENGTH', {length: type.minlength.requiredLength});
    }

    private handleMaxLength(type: any) {
        return this.translateService.instant('VALIDATION_ERROR.MAX_LENGTH', {length: type.maxlength.requiredLength});
    }

    private handlePattern(type: any) {
        return this.translateService.instant('VALIDATION_ERROR.PATTERN');
    }
}
