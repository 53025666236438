import { Ticket } from "./ticket.model";

export class GameWithTicket {
id: number;
  name: string;
  gameInfo: SposGame;
  canBuyTicket: boolean;
  ticketExists: boolean;
  tickets: Ticket[];
  ticketingEventId: string;
}

export class SposGame {
  id?: number;
  gameNr: string;
  federation: string;
  status: GameStatus;
  certifiedPunishmentReason?: string;
  date: Date;
  location: Location;
  teamName1: string;
  teamName2: string;
  quartersTeam1: number[];
  quartersTeam2: number[];
  finalScoreTeam1: number;
  finalScoreTeam2: number;
  scoreQuality: ScoreQuality;
  overtime: boolean;
  referee1: string;
  referee2?: string;
  referee3?: any;
  bestPlayersTeam1: PlayerStats[];
  bestPlayersTeam2: PlayerStats[];
}

export class Location {
  id: number;
  cityName: string;
  streetName: string;
  zipNumber: string;
  houseNumber: string;
  name: string;
}

export class PlayerStats {
  id: number;
  playerId: number;
  playerFirstName: string;
  playerLastName: string;
  points: number;
}

export enum GameStatus {
  NoDate = 'NO_DATE',
  Scheduled = 'SCHEDULED',
  Played = 'PLAYED',
  Approved = 'APPROVED',
  Penalized_A = 'PENALIZED_AGAINST_A',
  Penalized_B = 'PENALIZED_AGAINST_B',
  Deleted = 'DELETED',
  Penalized_Both = 'PENALIZED_AGAINST_BOTH'
}

export enum ScoreQuality {
  Full = 'QUARTERLY',
  Half = 'HALFTIME',
  Lite = 'ONLY_ENDSCORE',
  Error = 'NO_VALID_RESULT'
}

export class Quarter {
    name: string;
    scoreTeam1: number;
    scoreTeam2: number;
}
