import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { add, isBefore } from 'date-fns';
import { GameStatus, GameWithTicket, Quarter, ScoreQuality } from 'src/app/models/game.model';
import { AuthService } from 'src/app/services/auth.service';
import { GameService } from 'src/app/services/game.service';

@Component({
    selector: 'app-game-card',
    templateUrl: './game-card.component.html',
    styleUrls: ['./game-card.component.scss'],
})
export class GameCardComponent {
    @Input() game: GameWithTicket;

    @Input() showDetailsButton = true;
    @Input() showCheckoutButton = true;
    @Input() showGameName = false;
    @Input() showQuarterResults = true;

    constructor(private router: Router,
        public gameService: GameService,
        private translate: TranslateService,
        public authService: AuthService
    ) { }

    public isPlayed(): boolean {
        return this.game.gameInfo.status === GameStatus.Played ||
            this.game.gameInfo.status === GameStatus.Penalized_A ||
            this.game.gameInfo.status === GameStatus.Penalized_B ||
            this.game.gameInfo.status === GameStatus.Penalized_Both;
    }

    public isCheckoutEnabled(): boolean {
        const endOfGame = add(new Date(this.game?.gameInfo.date), {hours: 2});
        return this.game?.canBuyTicket && isBefore(new Date(), endOfGame);
    }

    public isBBCNord(teamName: string): boolean {
        return teamName === 'COLDAMARIS BBC Nord Dragonz';
    }

    public isHome(): boolean {
        return this.game.gameInfo.teamName1 === 'COLDAMARIS BBC Nord Dragonz';
    }

    public getCompetitionFromGameNr(): string {
        return this.game.gameInfo.gameNr.split(' - ')[0];
    }

    public getGameName() {
        if (this.isHome()) {
            return this.translate.instant('GAME.HOME_VS', {teamName: this.game.gameInfo.teamName2});
        } else {
            return this.translate.instant('GAME.AWAY_VS', {teamName: this.game.gameInfo.teamName1});
        }
    }

    public hasQuarterlyScore() {
        return !(this.game.gameInfo.scoreQuality === ScoreQuality.Error || this.game.gameInfo.scoreQuality === ScoreQuality.Lite);
    }

    public getQuarters(): Quarter[] {
        const quarters: Quarter[] = [];

        const quartersTeam1 = this.game.gameInfo.quartersTeam1;
        const quartersTeam2 = this.game.gameInfo.quartersTeam2;

        if (this.game.gameInfo.scoreQuality === ScoreQuality.Half) {
            quarters.push({ name: 'HT1', scoreTeam1: quartersTeam1[1], scoreTeam2: quartersTeam2[1] });
            quarters.push({ name: 'HT2', scoreTeam1: quartersTeam1[3] - quartersTeam1[1], scoreTeam2: quartersTeam2[3] - quartersTeam2[1] });
        }

        if (this.game.gameInfo.scoreQuality === ScoreQuality.Full) {
            quarters.push({ name: 'Q1', scoreTeam1: quartersTeam1[0], scoreTeam2: quartersTeam2[0] });
            quarters.push({ name: 'Q2', scoreTeam1: quartersTeam1[1] - quartersTeam1[0], scoreTeam2: quartersTeam2[1] - quartersTeam2[0] });
            quarters.push({ name: 'Q3', scoreTeam1: quartersTeam1[2] - quartersTeam1[1], scoreTeam2: quartersTeam2[2] - quartersTeam2[1] });
            quarters.push({ name: 'Q4', scoreTeam1: quartersTeam1[3] - quartersTeam1[2], scoreTeam2: quartersTeam2[3] - quartersTeam2[2] });
        }

        if (this.game.gameInfo.quartersTeam1[4] && this.game.gameInfo.quartersTeam2[4]) {
            quarters.push({ name: 'OT', scoreTeam1: quartersTeam1[4] - quartersTeam1[3], scoreTeam2: quartersTeam2[4] - quartersTeam2[3] });
        }

        return quarters;
    }

    public getLogoByTeam(team: string) {
        switch (team) {
            case 'COLDAMARIS BBC Nord Dragonz':
                return '/assets/images/B2L Logos/BBC Nord.png'; // TODO
            case 'Unger Steel Gunners Oberwart':
                return '/assets/images/BSL Logos/Oberwart Gunners.png';
            case 'SKN St. Pölten':
                return '/assets/images/BSL Logos/SKN St. Pölten.png';
            case 'UBSC Raiffeisen Graz':
                return '/assets/images/BSL Logos/UBSC Graz.png';
            case 'Raiffeisen Flyers Wels':
                return '/assets/images/BSL Logos/Flyers Wels.png';
            case 'BC GGMT Vienna':
                return '/assets/images/BSL Logos/GGMT Vienna.png';
            case 'Vienna Timberwolves':
                return '/assets/images/BSL Logos/Vienna Timberwolves.png';
            case 'Kapfenberg Bulls':
                return '/assets/images/BSL Logos/Kapfenberg Bulls.png';
            case 'BK IMMOunited Dukes Klosterneuburg':
                return '/assets/images/BSL Logos/BK Dukes.png';
            case 'Lopoca Panthers Fürstenfeld':
                return '/assets/images/B2L Logos/Fürstenfeld Panthers.png'; // TODO
            case 'Arkadia Traiskirchen Lions':
                return '/assets/images/BSL Logos/Traiskirchen Lions.png';
            case 'Swans Gmunden':
                return '/assets/images/BSL Logos/Swans Gmunden.png';
            case 'Basket Flames':
                return '/assets/images/B2L Logos/Basket Flames.png';
            case 'BBU Salzburg':
                return '/assets/images/B2L Logos/BBU Salzburg.png';
            case 'BK Mattersburg Rocks':
                return '/assets/images/B2L Logos/BK Mattersburg.png';
            case 'Güssing/Jennersdorf Blackbirds':
                return '/assets/images/B2L Logos/Blackbirds.png';
            case 'Raiffeisen Dornbirn Lions':
                return '/assets/images/B2L Logos/Dornbirn Lions.png';
            case 'Future Team Steiermark':
                return '/assets/images/B2L Logos/Future Team.png';
            case 'KOS Celovec':
                return '/assets/images/B2L Logos/KOS Celovec.png';
            case 'Kufstein Towers':
                return '/assets/images/B2L Logos/Kufstein Towers.png';
            case 'Mistelbach Mustangs':
                return '/assets/images/B2L Logos/Mistelbach Mustangs.png';
            case 'SWARCO Raiders Tirol':
                return '/assets/images/B2L Logos/Swarco Raiders.png';
            case 'UDW Alligators':
                return '/assets/images/B2L Logos/UDW Alligator.png';
            case 'Wörthersee Piraten':
                return '/assets/images/B2L Logos/Wörthersee Piraten.png';
            default:
                return '/assets/images/basketball-outline.svg';
        }
    }

    async showTicketCheckout(event: any) {
        event.stopPropagation();
        this.router.navigate(['/checkout/' + this.game.ticketingEventId]);
    }

    async showGameDetails(event: any) {
        event.stopPropagation();
        this.router.navigate(['/game/' + this.game.id]);
    }
}
