import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Person } from '../models/person.models';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    constructor(private http: HttpClient) {}

    isInitialRegistrationDone(): Observable<boolean> {
        return this.getProfile().pipe(
            catchError((value) => of(null)),
            switchMap((value: Person) => of(value !== null))
        );
    }

    completeRegistration(person: Person): Observable<Person> {
        return this.http.put<Person>(environment.restUrl + 'me', person);
    }

    getProfile(): Observable<Person> {
        return this.http.get<Person>(environment.restUrl + 'me');
    }

    deleteProfile(): Observable<any> {
        return this.http.delete<any>(environment.restUrl + 'me');
    }
}
