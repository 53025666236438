import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { GameCardComponent } from './game-card/game-card.component';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, TranslateModule],
  declarations: [
    GameCardComponent
  ],
  exports: [
    GameCardComponent
  ]
})
export class SharedModule {
}
