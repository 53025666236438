/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
  production: true,
  restUrl: 'https://api.test.bbc-nord.at/rest/',

  authConfig: {
    clientId: 'web_app',
    realm: 'BBC-Nord',
    serverUrl: 'https://oauth.test.datasys.at/auth/',
    //baseUri: 'http://localhost:8100',
    baseUri: 'https://app.test.bbc-nord.at',
    redirectPath: '/tabs/tab/home',
    logoutPath: '/menu'
  }
};
