import {NgModule} from '@angular/core';
import {InputErrorPipe} from './input-error.pipe';

@NgModule({
    imports: [],
    declarations: [InputErrorPipe],
    exports: [InputErrorPipe]
})
export class PipeModule {
    static forRoot() {
        return {
            ngModule: PipeModule,
            providers: []
        };
    }
}
