import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, from, lastValueFrom, Observable, of, timeout } from 'rxjs';
import { Person } from '../models/person.models';
import { KeycloakService, KeycloakEventType } from 'keycloak-angular';
import { Router } from '@angular/router';
import { ProfileService } from './profile.service';
import { Platform } from '@ionic/angular';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private authenticatedPerson$: BehaviorSubject<Person> = new BehaviorSubject(null);
  private authenticatedUser$: BehaviorSubject<any> = new BehaviorSubject(null);

  private isLoginOngoing$: Promise<any> = null;;

  constructor(
    private profileService: ProfileService,
    private keycloakService: KeycloakService,
    private zone: NgZone,
    private router: Router,
    private platform: Platform
  ) {
    keycloakService.keycloakEvents$.subscribe({
      next: (e) => {
        if (e.type === KeycloakEventType.OnTokenExpired) {
          keycloakService.updateToken(20);
        } else if (
          e.type === KeycloakEventType.OnAuthSuccess ||
          e.type === KeycloakEventType.OnAuthRefreshSuccess
        ) {
          zone.run(async () => {
            this.isLoggedIn$.next(true);
            this.loadAuthenticatedPerson();
            this.loadUserProfile();
          });
        } else if (
          e.type === KeycloakEventType.OnAuthError ||
          e.type === KeycloakEventType.OnAuthRefreshError ||
          e.type === KeycloakEventType.OnAuthLogout
        ) {
          zone.run(async () => {
            this.isLoggedIn$.next(false);
            this.authenticatedPerson$.next(null);
            this.authenticatedUser$.next(null);
          });
        }
      },
    });
  }

  isLoginOngoing(): Observable<boolean> {
    if (this.isLoginOngoing$) {
      return from(this.isLoginOngoing$).pipe(
        timeout({ first: 1_000 , with: () => of(false)})
      );
    }
    return of(false);
  }

  isLoggedIn() {
    return this.isLoggedIn$.value;
  }

  login(redirectPath?: string) {
    const redirectBaseUri = this.platform.is('ios') ? 'bbcnord:/' : environment.authConfig.baseUri;
    this.isLoginOngoing$ = this.keycloakService.login({
      redirectUri: redirectBaseUri + (redirectPath ?? environment.authConfig.redirectPath),
    });
  }

  async loadUserProfile() {
    await this.keycloakService
      .loadUserProfile(true)
      .then((user) => this.authenticatedUser$.next(user));
  }

  getAuthenticatedUser(): any {
    return this.authenticatedUser$.value;
  }

  getAuthenticatedPerson(): Person {
    return this.authenticatedPerson$.value;
  }

  async loadAuthenticatedPerson() {
    const person$ = this.profileService.getProfile();
    const person = await lastValueFrom(person$);
    this.authenticatedPerson$.next(person);
  }

  logout(): void {
    const redirectBaseUri = this.platform.is('ios') ? 'bbcnord:/' : environment.authConfig.baseUri;
    this.keycloakService.logout(redirectBaseUri + environment.authConfig.logoutPath);
  }

  register(): void {
    this.keycloakService.register();
  }

  refreshToken() {
    return this.keycloakService.updateToken(-1);
  }
}
